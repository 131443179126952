import React from "react";
import { Modal } from "antd";
import ModalContent from "../components/ModalContent";
const ERROR_COLOR = "#ff4d4f";
const SUCCESS_COLOR = "#508553";
export default class DialogUtils {
  /**
   *
   * @param content
   * @param onOk
   * @param onCancel
   */
  static confirm(
    content: any,
    onOk: Function,
    onCancel?: Function,
    okText?: string,
    cancelText?: string
  ) {
    Modal.confirm({
      icon: null,
      centered: true,
      width: 330,
      className: "success-modal",
      okButtonProps: {
        id: "modal-success-btn",
        style: {
          width: "125px",
          marginBottom: "10px",
          height: "50px",
          borderRadius: "50px",
          backgroundColor: SUCCESS_COLOR,
          borderColor: SUCCESS_COLOR,
          marginLeft: "12px",
          marginRight: "4px",
        },
      },
      cancelButtonProps: {
        id: "modal-cancel-btn",
        style: { width: "125px", marginBottom: "15px", height: "50px", borderRadius: "50px" },
      },
      content: <ModalContent type={"confirm"} content={content} title={"Alert"} />,
      onOk: () => {
        if (onOk) onOk();
      },
      onCancel: () => {
        if (onCancel) onCancel();
      },
    });
  }

  /**
   *
   * @param content
   * @param onOk
   */
  static error(content: any, onOk?: Function) {
    Modal.error({
      icon: null,
      centered: true,
      width: 330,
      className: "error-modal",
      okButtonProps: {
        id: "modal-error-btn",
        style: {
          width: "266px",
          marginBottom: "10px",
          height: "50px",
          borderRadius: "50px",
          backgroundColor: ERROR_COLOR,
          borderColor: ERROR_COLOR,
        },
      },
      content: <ModalContent title="Error" type={"error"} content={content} />,
      onOk: () => {
        if (onOk) onOk();
      },
    });
  }

  /**
   *
   * @param content
   * @param onOk
   */
  static success(content: any, onOk?: Function) {
    Modal.success({
      icon: null,
      centered: true,
      width: 330,
      className: "success-modal",
      okButtonProps: {
        id: "modal-success-btn",
        style: {
          width: "266px",
          marginBottom: "10px",
          height: "50px",
          borderRadius: "50px",
          backgroundColor: SUCCESS_COLOR,
          borderColor: SUCCESS_COLOR,
        },
      },
      content: <ModalContent title="Success" type={"success"} content={content} />,
      onOk: () => {
        if (onOk) onOk();
      },
    });
  }

  /**
   *
   * @param content
   * @param onOk
   */
  static loading(content: any, onOk?: Function) {
    Modal.info({
      icon: null,
      centered: true,
      width: 330,
      okButtonProps: { style: { display: 'none' } },
      content: <ModalContent title="Loading" type={"loading"} content={content} />,
      onOk: () => {
        if (onOk) onOk();
      },
    });
  }
}

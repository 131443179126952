import React from "react";
import styled from "styled-components";

interface IconsProps {
  size?: string;
  color?: string;
  hoverColor?: string;
  margin?: string;
  isMirrored?:boolean;
}

//custom styled component for Icon Wrapper

const StyledIconContainer = styled.div<IconsProps>`
  display: inline-block;
  width: ${(props) => (props.size ? props.size : "1em")};
  height: ${(props) => (props.size ? props.size : "1em")};
  margin: ${(props) => props.margin};

  transform: ${(props) => (props.isMirrored ? "scale(-1,1)" : null)}
`;

const StyledSortIconContainer = styled.div<IconsProps>`
  display: inline-block;
  padding-top: 2px;
`;

const StyledSvg = styled.svg<IconsProps>`
  fill: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
  transition: fill 0.3s ease;
  width: ${(props) => props.size} !important;
  height: ${(props) => props.size} !important;

  &:hover {
    fill: ${(props) => (props.hoverColor ? props.hoverColor : props.theme.colors.secondary)} !important;
  }
`;

export const StyledCustomerIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <g />
          <g>
            <path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h4v-3C23,14.82,19.43,13.53,16.67,13.13z" />
            <path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76 C14.09,11.9,14.53,12,15,12z" />
            <path d="M9,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S5,5.79,5,8C5,10.21,6.79,12,9,12z M9,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S7,9.1,7,8C7,6.9,7.9,6,9,6z" />
            <path d="M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z M15,18H3l0-0.99C3.2,16.29,6.3,15,9,15s5.8,1.29,6,2V18z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledTellerIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path d="M260-630q-24.75 0-42.375-17.625T200-690v-130q0-24.75 17.625-42.375T260-880h440q24.75 0 42.375 17.625T760-820v130q0 24.75-17.625 42.375T700-630H260Zm0-60h440v-130H260v130ZM140-80q-24.75 0-42.375-17.625T80-140v-70h800v70q0 24.75-17.625 42.375T820-80H140ZM80-240l145-324q8-16 22.603-26 14.603-10 31.397-10h402q16.794 0 31.397 10Q727-580 735-564l145 324H80Zm260-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledStoreIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <g />
          <g>
            <path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledTuneIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledFilterIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} size={size} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#508553">
        <path
          d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226 3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544 4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974 14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608 4.17204 20.2336 4.03323 20.17 3.91Z"
          fill="#508553"
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledCollapseSideMenuLeft = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} size={size} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#508553">
        <g data-name="Layer 2">
          <g data-name="menu-arrow">
            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
            <path d="M20.05 11H5.91l1.3-1.29a1 1 0 0 0-1.42-1.42l-3 3a1 1 0 0 0 0 1.42l3 3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L5.91 13h14.14a1 1 0 0 0 .95-.95V12a1 1 0 0 0-.95-1z" />
            <rect x="3" y="17" width="18" height="2" rx=".95" ry=".95" />
            <rect x="3" y="5" width="18" height="2" rx=".95" ry=".95" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
}

export const StyledCollapseSideMenuRight = ({ size, color, hoverColor, isMirrored }: IconsProps) => {
  return (
    <StyledIconContainer size={size} isMirrored>
      <StyledSvg color={color} size={size} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#508553">
        <g data-name="Layer 2">
          <g data-name="menu-arrow">
            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
            <path d="M20.05 11H5.91l1.3-1.29a1 1 0 0 0-1.42-1.42l-3 3a1 1 0 0 0 0 1.42l3 3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L5.91 13h14.14a1 1 0 0 0 .95-.95V12a1 1 0 0 0-.95-1z" />
            <rect x="3" y="17" width="18" height="2" rx=".95" ry=".95" />
            <rect x="3" y="5" width="18" height="2" rx=".95" ry=".95" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
}

export const StyledAdminManagementIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <g>
            <path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z" />
            <path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z" />
            <path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledAdminProfileIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledGearIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledBurgerIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledHistoryIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledReportIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <g>
            <path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M5,19V5h9v5h5v9H5z M9,8 c0,0.55-0.45,1-1,1S7,8.55,7,8s0.45-1,1-1S9,7.45,9,8z M9,12c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,11.45,9,12z M9,16 c0,0.55-0.45,1-1,1s-1-0.45-1-1s0.45-1,1-1S9,15.45,9,16z" />
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledVoucherIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01 4 11V4h7v-.01l9 9-7 7.02z" />
        <circle cx="6.5" cy="6.5" r="1.5" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledProductIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="M480-120q-117 0-198.5-81.5T200-400q0-100 61.5-176.5T420-674q-26-5-50-14.5T327-717q-28-29-37.5-67t-9.5-78v-18q86-2 148 56.5T489-680q14-43 39.5-80t57.5-69q9-9 21-9t21 9q9 9 9 21t-9 21q-25 25-45 54t-34 61q94 24 152.5 99.5T760-400q0 117-81.5 198.5T480-120Zm0-60q92 0 156-64t64-156q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 92 64 156t156 64Zm0-220Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledOptionsIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        version="1.1"
        id="Uploaded to svgrepo.com"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <style type="text/css"> </style>
          <g>
            <g>
              <path d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z" />
              <circle cx="10" cy="16" r="3" />
              <path d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z" />
              <rect x="15" y="8" width="10" height="2" />
              <rect x="15" y="15" width="10" height="2" />
              <rect x="15" y="22" width="10" height="2" />
            </g>
            <g>
              <path d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z" />
              <circle cx="10" cy="16" r="3" />
              <path d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10 c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z" />
              <rect x="15" y="8" width="10" height="2" />
              <rect x="15" y="15" width="10" height="2" />
              <rect x="15" y="22" width="10" height="2" />
            </g>
          </g>
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledEditIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" >
        <path
          d="M6.40393 14.6569C5.92037 15.2545 5.70769 15.3973 5.03151 15.6658C3.99016 16.0858 2.08203 16.8588 0.840035 17.3611C0.60528 17.4607 -0.281571 17.4109 0.0896222 16.4712C0.510977 15.1635 1.12896 13.2323 1.4821 12.1302C1.73893 11.353 1.84125 11.1516 2.35892 10.6298L7.70611 4.85986L11.4923 8.94538C11.4923 8.94538 7.89271 12.9876 6.40393 14.6569Z"
          fill={color}
        />
        <path d="M12.3076 8.06862L8.52344 3.98526L9.33404 3.1084L13.1202 7.19392L12.3076 8.06862Z" fill={color} />
        <path
          d="M15.5519 4.56725L13.9307 6.31881L10.1445 2.23329L11.7678 0.483897C12.3657 -0.161299 13.3328 -0.161299 13.9307 0.483897L15.5539 2.23329C16.1498 2.87632 16.1498 3.92206 15.5519 4.56725Z"
          fill={color}
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledEditIconV2 = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path
          d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <polygon
          fill="none"
          points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledTrashIcon = ({ size, color, hoverColor, margin }: IconsProps) => {
  return (
    <StyledIconContainer size={size} margin={margin}>
    <StyledSvg color={color} size={size} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color}>
        <path
          d="M15.4073 6.99951C14.9208 6.99951 14.5964 7.34951 14.5964 7.87452V18.1996C14.5964 18.8996 14.1099 19.2496 13.6233 19.2496H4.21694C3.56822 19.2496 3.24386 18.7246 3.24386 18.1996V7.87452C3.24386 7.34951 2.9195 6.99951 2.43297 6.99951C1.94643 6.99951 1.62207 7.34951 1.62207 7.87452V18.1996C1.62207 19.7746 2.75733 20.9996 4.21694 20.9996H13.4612C14.9208 20.9996 16.056 19.7746 16.056 18.1996V7.87452C16.2182 7.34951 15.8938 6.99951 15.4073 6.99951Z"
          fill={color ? color : "#5B5B5B"}
        />
        <path
          d="M7.62765 16.6246V7.87452C7.62765 7.34951 7.30329 6.99951 6.97894 6.99951C6.65458 6.99951 6.00586 7.34951 6.00586 7.87452V16.6246C6.00586 17.1496 6.33022 17.4996 6.81676 17.4996C7.30329 17.4996 7.62765 17.1496 7.62765 16.6246Z"
          fill={color ? color : "#5B5B5B"}
        />
        <path
          d="M11.8405 16.6246V7.87452C11.8405 7.34951 11.354 6.99951 10.8675 6.99951C10.3809 6.99951 10.2188 7.34951 10.2188 7.87452V16.6246C10.2188 17.1496 10.5431 17.4996 10.8675 17.4996C11.1918 17.4996 11.8405 17.1496 11.8405 16.6246Z"
          fill={color ? color : "#5B5B5B"}
        />
        <path
          d="M17.0288 3.50003H12.9743V2.27502C12.9743 1.05001 12.0013 0 10.866 0H6.97371C5.83845 0 4.86538 1.05001 4.86538 2.27502V3.50003H0.810896C0.324358 3.50003 0 3.85004 0 4.37504C0 4.90005 0.324358 5.25005 0.810896 5.25005H17.0288C17.5154 5.25005 17.8397 4.90005 17.8397 4.37504C17.8397 3.85004 17.5154 3.50003 17.0288 3.50003ZM6.48717 2.27502C6.48717 1.92502 6.64935 1.75002 6.97371 1.75002H10.866C11.1904 1.75002 11.3525 1.92502 11.3525 2.27502V3.50003H6.48717V2.27502Z"
          fill={color ? color : "#5B5B5B"}
        />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledViewIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Zm0-300Zm-.169 240Q601-260 702.5-325.5 804-391 857-500q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359-740 257.5-674.5 156-609 102-500q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledMapPinIcon = ({ size, color, hoverColor, margin }: IconsProps) => {
  return (
    <StyledIconContainer size={size} margin={margin}>
      <StyledSvg color={color} hoverColor={hoverColor} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledResetIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M13,3c-4.97,0-9,4.03-9,9c0,0.06,0.01,0.12,0.01,0.19l-1.84-1.84l-1.41,1.41L5,16l4.24-4.24l-1.41-1.41l-1.82,1.82 C6.01,12.11,6,12.06,6,12c0-3.86,3.14-7,7-7s7,3.14,7,7s-3.14,7-7,7c-1.9,0-3.62-0.76-4.88-1.99L6.7,18.42 C8.32,20.01,10.55,21,13,21c4.97,0,9-4.03,9-9S17.97,3,13,3z M15,11v-1c0-1.1-0.9-2-2-2s-2,0.9-2,2v1c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-3C16,11.45,15.55,11,15,11z M14,11h-2v-1c0-0.55,0.45-1,1-1s1,0.45,1,1V11z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledCheckIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledWrongIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M15.73,3H8.27L3,8.27v7.46L8.27,21h7.46L21,15.73V8.27L15.73,3z M19,14.9L14.9,19H9.1L5,14.9V9.1L9.1,5h5.8L19,9.1V14.9z M14.83,7.76L12,10.59L9.17,7.76L7.76,9.17L10.59,12l-2.83,2.83l1.41,1.41L12,13.41l2.83,2.83l1.41-1.41L13.41,12l2.83-2.83 L14.83,7.76z" />
        </g>
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledChevronLeftIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const CheckIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 7.5L7.47368 13L18 2" stroke="#508553" stroke-width="3" stroke-linecap="round" />
    </svg>
  );
};

export const StyledChevronRightIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledSortArrowsIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledSortIconContainer>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="M322-450v-316L202-646l-42-42 193-193 193 193-42 42-122-121v317h-60ZM607-81 414-275l42-41 120 120v-316h60v317l122-121 42 42L607-81Z" />
      </StyledSvg>
    </StyledSortIconContainer>
  );
};

export const StyledCloseIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledDownloadIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="M480-313 287-506l43-43 120 120v-371h60v371l120-120 43 43-193 193ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z" />
        ;
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledProgramIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg color={color} hoverColor={hoverColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
        <path d="M680-320q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480q-17 0-28.5 11.5T640-440q0 17 11.5 28.5T680-400ZM440-40v-116q0-21 10-39.5t28-29.5q32-19 67.5-31.5T618-275l62 75 62-75q37 6 72 18.5t67 31.5q18 11 28.5 29.5T920-156v116H440Zm79-80h123l-54-66q-18 5-35 13t-34 17v36Zm199 0h122v-36q-16-10-33-17.5T772-186l-54 66Zm-76 0Zm76 0Zm-518 0q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v200q-16-20-35-38t-45-24v-138H200v560h166q-3 11-4.5 22t-1.5 22v36H200Zm80-480h280q26-20 57-30t63-10v-40H280v80Zm0 160h200q0-21 4.5-41t12.5-39H280v80Zm0 160h138q11-9 23.5-16t25.5-13v-51H280v80Zm-80 80v-560 137-17 440Zm480-240Z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

export const StyledHomeDeliveryIcon = ({ size, color, hoverColor }: IconsProps) => {
  return (
    <StyledIconContainer size={size}>
      <StyledSvg
        color={color}
        hoverColor={hoverColor}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
      </StyledSvg>
    </StyledIconContainer>
  );
};

import { createGlobalStyle } from "styled-components";
import PoppinsMedium from '../assets/fonts/Poppins/Poppins-Medium.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsMedium}) format('truetype');
    /* Add more font formats (e.g., woff, woff2) as needed */
  }

  body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    overflow-x:hidden;
  }
`;

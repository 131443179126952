import { Button, Input, Select, Tabs, Upload, Image, InputNumber, Switch, DatePicker, Radio, AutoComplete, Col, TimePicker } from "antd";
import Search from "antd/lib/input/Search";
import styled from "styled-components";
import { PatternFormat } from "react-number-format";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
interface ContainerProps {
  alignCenter?: boolean;
  alignitems?: string;
  alignTop?: boolean;
  background?: string;
  borderradius?: string;
  column?: boolean;
  gap?: string;
  height?: string;
  hoverColor?: string;
  isBorder?: boolean;
  isJustifyRight?: boolean;
  isjustifyleft?: boolean;
  isFlexEnd?: boolean;
  isAddressContainer?: boolean;
  isShrink?: boolean;
  justifyCenter?: boolean;
  margin?: string;
  marginbottom?: string;
  margintop?: string;
  marginleft?: string;
  padding?: string;
  spaceBetween?: boolean;
  vertical?: boolean;
  width?: string;
}

interface RadioProps {
  margin?: string;
}
interface AnchorTypes {
  color?: string;
  alignCenter?: boolean;
}
interface CommonButtonProps {
  focuscolor?: string;
  width?: string;
  height?: string;
  background?: string;
  backgroundhover?: string;
  color?: string;
  transparent?: boolean;
  justifyCenter?: boolean;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  marginleft?: string;
  padding?: string;
  key?: string;
  hideOnMobile?: boolean;
  borderradius?: string;
}
interface CommonTextProps {
  fontSize?: string;
  width?: string;
  color?: string;
  fontWeight?: string;
  isShrink?: boolean;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  paddingLeft?: string;
  lineHeight?: number;
  vertical?: boolean;
  isHover?: boolean;
  hoverColor?: string;
  textAlign?: string;
}

interface CommonInputProps {
  color?: string;
  width?: string;
  addonbefore?: boolean;
  confirmReset?: boolean;
  isReport?: boolean;
}

interface SelectInputProps {
  color?: string;
  width?: string;
  height?: string;
  isSmallSelect?: boolean;
  noIndent?: any;
}

interface AutoCompleteProps {
  color?: string;
  width?: string;
  height?: string;
  addonbefore?: boolean;
  confirmReset?: boolean;
  isReport?: boolean;
  onSelect?: (value: string, option: { label: string; id: string }) => void;
}

interface CommonPageContentProps {
  isAdminProfile?: boolean;
  isHomeDeliveryManagement?: boolean;
}

interface CommonDatePickerProps {
  isReport?: boolean;
  width?: string;
}

interface CommonTimePickerProps {
  isReport?: boolean;
}

interface CommonBreakContainerProps {
  margin?: string;
  font?: string;
  width?: string;
}

interface CommonColProps {
  padding?: string;
}

export const CommonAlignCenterContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 37px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  flex-direction: ${(props) => props.column && "column"};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  margin-left: ${(props) => props.marginleft};

  border: ${(props) => props.isAddressContainer && `1px solid ${props.theme.colors.secondary}`} !important;
  border-radius: ${(props) => props.isAddressContainer && "10px"};
  padding: ${(props) => props.isAddressContainer && "1em"};
`;
export const CommonFlexContainer = styled.div<ContainerProps>`
  display: flex;
  width: ${(props) => props.width};
  gap: ${(props) => props.gap};
  background-color: ${(props) => (props.background ? props.theme.colors[props.background!] : "transparent")} !important;
  align-items: ${(props) => props.alignCenter && "center"};
  align-items: ${(props) => props.alignitems};
  justify-content: ${(props) =>
    props.justifyCenter
      ? "center"
      : props.spaceBetween
      ? "space-between"
      : props.isJustifyRight
      ? "right"
      : props.isFlexEnd
      ? "flex-end"
      : props.isjustifyleft
      ? "left"
      : ""};
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : null)};
  transition: color 0.3s ease;
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.margintop};
  margin-bottom: ${(props) => props.marginbottom};
  margin-left: ${(props) => props.marginleft};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  border-radius: ${(props) => props.borderradius};
  border: ${(props) => props.isBorder && `1px solid ${props.theme.colors.secondary}`};

  transform-origin: ${(props) => (props.vertical ? "left" : "")};
  transform: ${(props) => (props.vertical ? "rotate(-90deg)" : "none")};

  &:hover {
    cursor: ${(props) => props.hoverColor && "pointer"};
    color: ${(props) => props.theme.colors[props.hoverColor!]};
  }

  svg {
    height: 1.4em;
    width: 1.4em;
  }

  @media (max-height: 680px) {
    // margin-top: 10px;
    font-size: ${(props) => props.isShrink && props.theme.fontSizes.small};
    svg {
      height: 1em;
      width: 1em;
    }
  }
`;

export const CommonSortContainer = styled.div`
  display: flex;
  margin: 0px 0px 0px 10px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
`;

export const CommonRadio = styled(Radio)<RadioProps>`
  margin: ${(props) => props.margin};
  .ant-radio-inner::after {
    background-color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  .ant-radio-checked::after {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

export const CommonButton = styled(Button)<CommonButtonProps>`
  background-color: ${(props) => (props.background ? props.theme.colors[props.background!] : "transparent")} !important;
  width: ${(props) => props.width};
  color: ${(props) => props.theme.colors[props.color!] || props.theme.colors.primary};
  border-color: ${(props) => props.theme.colors[props.background!] || props.theme.colors.primary};
  height: ${(props) => (props.height ? props.height : "45px")};
  border-radius: ${(props) => (props.borderradius ? props.borderradius : "50px")} !important;
  display: ${(props) => props.justifyCenter && "flex"};
  align-items: ${(props) => props.justifyCenter && "center"};
  justify-content: ${(props) => props.justifyCenter && "center"};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginleft};
  padding: ${(props) => props.padding};

  .ant-btn-primary:focus,
  &:focus {
    background: ${(props) => (props.transparent ? "transparent" : props.theme.colors.primary)} !important;
    border-color: ${(props) => (props.transparent ? "transparent" : props.theme.colors.primary)} !important;
    color: ${(props) =>
      props.focuscolor ? props.theme.colors[props.focuscolor!] : props.transparent ? "transparent" : props.theme.colors.white} !important;
  }

  .ant-btn-primary:focus,
  &:focus {
    background: ${(props) => (props.background ? props.theme.colors[props.background!] : "transparent")} !important;
  }

  &:hover {
    background-color: ${(props) =>
      props.backgroundhover
        ? props.theme.colors[props.backgroundhover!]
        : props.transparent
        ? "transparent"
        : props.theme.colors.secondary} !important;
    border-color: ${(props) => (props.transparent ? "transparent" : props.theme.colors.secondary)} !important;
    color: ${(props) => props.theme.colors[props.background! === "primary" ? "primary" : "white"]};
  }

  &:hover {
    p {
      color: ${(props) => (props.key === "cancel" ? props.theme.colors.primary : "white")};
    }
  }

  &:focus {
    background-color: ${(props) => props.key === "cancel" && props.theme.colors.white};
  }

  .ant-btn:active {
    background-color: ${(props) => props.key === "cancel" && props.theme.colors.secondary};
  }

  /* Hide the component on screens 768px or below */
  @media (max-width: 767px) {
    display: ${(props) => props.hideOnMobile && "none !important"};
  }
`;

export const CommonAnchor = styled.a<AnchorTypes>`
  color: ${(props) => props.theme.colors[props.color!]};
  display: ${(props) => props.alignCenter && "flex"};
  gap: ${(props) => props.alignCenter && "10px"};

  &:hover {
    color: ${(props) => props.theme.colors[props.color === "secondary" ? "primary" : "secondary"]};
  }
`;

export const CommonInput = styled(Input)<CommonInputProps>`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 50px !important;
  color: ${(props) => props.theme.colors[props.color!]} !important;
  padding: ${(props) => (props.addonbefore ? "10px 10px 10px 20px" : "10px 10px 10px 20px")};
  width: ${(props) => props.width};

  .ant-input {
    border: 0px;
    border-radius: 50px;
    padding: -10px;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-input-group-addon {
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const CommonMaskInput = styled(PatternFormat)<CommonInputProps>`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 50px;
  color: ${(props) => props.theme.colors.primary};
  padding: ${(props) => (props.addonbefore ? "10px 10px 10px 10px" : "10px 10px 10px 20px")};
  width: ${(props) => props.width};

  &:focus-visible {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: none;
  }
`;

export const CommonInputNumber = styled(InputNumber)<CommonInputProps>`
  border: 1px solid ${(props) => props.theme.colors.secondary} !important;
  border-radius: 50px !important;
  color: ${(props) => props.theme.colors[props.color!]} !important;
  padding: ${(props) => (props.addonbefore ? "10px 10px 10px 10px" : "10px 10px 10px 20px")};
  width: ${(props) => props.width};

  &::placeholder {
    color: ${(props) => props.theme.colors[props.color!]} !important;
  }

  .ant-input {
    border: 0px;
    border-radius: 50px;
    padding: -10px;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-input-group-addon {
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const CommonDatePicker = styled(DatePicker)<CommonDatePickerProps>`
  border-radius: 50px !important;
  border-color: ${(props) => props.theme.colors.secondary};
  padding: 10px 15px 10px 15px;
  min-width: ${(props) => (props.isReport ? "175px" : "100%")};
  wdith: ${(props) => props.width};

  input {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-picker-clear {
    transform: scale(1.2);
    margin-top: -7px;
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover,
  &.ant-picker-focused {
    border-color: ${(props) => props.theme.colors.primary} !important;
    border-right-width: 1px;
  }
`;

export const CommonRangePicker = styled(RangePicker)<CommonDatePickerProps>`
  border-radius: 50px !important;
  border-color: ${(props) => props.theme.colors.secondary};
  padding: 10px 15px 10px 15px;
  min-width: ${(props) => (props.isReport ? "175px" : "100%")};
  wdith: ${(props) => props.width};

  .ant-picker-clear {
    margin-top: -7px;
    margin-right: 4px;
    transform: scale(1.3);
    color: ${(props) => props.theme.colors.primary};
  }

  input {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover,
  &.ant-picker-focused {
    border-color: ${(props) => props.theme.colors.primary} !important;
    border-right-width: 1px;
  }
`;

export const CommonTimePicker = styled(TimePicker)<CommonTimePickerProps>`
  border-radius: 50px !important;
  border-color: ${(props) => props.theme.colors.secondary};
  padding: 10px 15px 10px 15px;
  min-width: ${(props) => (props.isReport ? "175px" : "100%")};
  color: ${(props) => props.theme.colors.primary} !important;

  ul .ant-picker-time-panel-column {
    height: 0px !important;
    background-color: yellow !important;
  }

  .ant-picker-clear {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-picker-input > input {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover,
  &.ant-picker-focused {
    border-color: ${(props) => props.theme.colors.primary} !important;
    border-right-width: 1px;
  }
`;

export const CommonTextArea = styled(TextArea)<CommonInputProps>`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 20px !important;
  color: ${(props) => props.theme.colors[props.color!]} !important;
  padding: 10px 10px 10px 20px;
  width: ${(props) => props.width};

  resize: none;
`;

export const CommonUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 140px;
    height: 140px;
    // background-color: ${(props) => props.theme.colors.secondary} ;
    border: 1px dashed ${(props) => props.theme.colors.primary};
  }

  .ant-upload-list-picture-card-container {
    width: 150px !important;
    height: 150px !important;
  }
  .ant-upload-text {
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-upload-text-max {
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
  }

  .ant-upload-picture-card-wrapper {
    width: unset;
  }
`;

export const CommonImage = styled(Image)`
  .ant-image-img {
    // margin-bottom: 15px !imporatant;
  }
`;

export const CommonAutoCompleteInput = styled(AutoComplete)<AutoCompleteProps>`
  color: ${(props) => props.theme.colors[props.color!]} !important;
  width: ${(props) => props.width};

  .ant-select-selector {
    width: 80% !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .dropdown {
    margin: 50px;
  }

  .ant-select-clear {
    width: 20px;
    height: 20px;
    transform: scale(1.3);
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-select-selection-search-input {
    padding-top: 8px !important;
    padding-left: 5px !important;
  }

  .ant-select-selector {
    height: 42px !important;
    width: 100% !important;
    padding-top: 5px !important;
    padding-left: 20px !important;
    border: 1px solid ${(props) => props.theme.colors.secondary} !important;
    border-radius: 50px !important;
    box-shadow: unset !important;
  }

  .ant-input-group-addon {
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const CommonSelect = styled(Select)<SelectInputProps>`
  color: ${(props) => props.theme.colors[props.color!]} !important;
  ${(props) => props?.noIndent ? '' : `text-indent: 2% !important;`}
  width: ${(props) => props.width};

  .ant-select-clear {
    width: 20px;
    height: 20px;
    transform: scale(1.3);
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-select-selection-item {
    padding-top: 0% !important;
  }

  .ant-select-selector {
    height: ${(props) => (props.height ? props.height : "42px")} !important;
    width: ${(props) => props.width} !important;
    padding-top: ${(props) => (props.isSmallSelect ? "2px" : "5px")} !important;
    border: 1px solid ${(props) => props.theme.colors.secondary} !important;
    border-radius: 50px !important;
    box-shadow: unset !important;
    font-size: ${(props) => props.height === "30px" && "12px"} !important;
    height: ${(props) => props.height === "30px" && "25px"} !important;
  }
  
  .ant-select-selection-search input {
    font-weight: bold !important; /* Change font weight */
  }
`;

export const CommonTab = styled(Tabs)`
  margin-top: 20px;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .ant-tabs-tab-btn {
    &:hover {
      color: ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

export const CommonOption = styled(Option)<SelectInputProps>``;

export const CommonInputSearch = styled(Search)<CommonInputProps>`
  display: flex;
  width: ${(props) => props.width};
  align-items: center;

  .ant-input-affix-wrapper {
    border: 1px solid ${(props) => props.theme.colors.secondary} !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    color: ${(props) => props.theme.colors[props.color!]} !important;
    padding: 10px 20px 10px 20px;
  }

  span {
    background-color: transparent !important;
  }

  .ant-input-clear-icon {
    padding: 0;
    transform: scale(1.3);
    color: ${(props) => props.theme.colors.primary};
  }

  button {
    border: 1px solid ${(props) => props.theme.colors.secondary} !important;
    color: ${(props) => props.theme.colors.primary} !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    height: ${(props) => (props.isReport ? "2.70em" : "2.75em")};
    width: 50px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors[props.color!]} !important;
  }
`;

export const CommonInputPassword = styled(Input.Password)<CommonInputProps>`
  border: 1px solid ${(props) => props.theme.colors.secondary} !important;
  border-radius: 50px !important;
  color: ${(props) => props.theme.colors[props.color!]} !important;
  padding: 10px 10px 10px 20px;
  width: ${(props) => props.width} !important;

  span {
    width: ${(props) => (props.confirmReset ? "40px" : "20px")} !important;
    display: flex;
    align-items: top;
    justify-center: center;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors[props.color!]} !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .ant-input-suffix {
    flex-direction: row-reverse;
    span {
      width: 40px !important;
    }
  }

  .ant-form-item-feedback-icon-error {
    z-index: 100;
    padding-left: 18px;
  }

  .ant-input-password-icon.anticon {
    justify-content: flex-end;
    padding-right: 8px;
  }

  .ant-input-suffix > *:not(:last-child) {
    padding-right: 0px;
  }
`;

export const CommonFormLabel = styled.label`
  color: ${(props) => props.theme.colors.primary};
`;

export const CommonAuthLogo = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;

export const CommonHeaderPage = styled.div`
  display: flex;
  height: 50px;
  border-radius: 10px;
  margin-left: 2em;

  p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
`;

export const CommonTextSmall = styled.p<CommonTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.2)};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};

  &:hover {
    color: ${(props) => props.isHover && props.hoverColor};
  }
`;

export const CommonTextMedium = styled.p<CommonTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.2")};
  display: flex;
  margin-top: ${(props) => props.marginTop};
  align-items: end;
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => (props.margin ? props.margin : "5px")};

  @media (max-height: 680px) {
    font-size: ${(props) => props.isShrink && "16px"};
  }
`;

export const CommonTextCustomSize = styled.p<CommonTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.2")};
  display: flex;
  align-items: end;
  text-align: ${(props) => props.textAlign};
  margin-top: ${(props) => props.marginTop};
  margin: ${(props) => props.margin};

  @media (max-height: 680px) {
    font-size: ${(props) => props.isShrink && "16px"};
  }
`;

export const CommonTextLarge = styled.p<CommonTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.theme.fontSizes.large};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.2)};
`;

export const CommonStyledPageContent = styled.div<CommonPageContentProps>`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.isAdminProfile || props.isHomeDeliveryManagement) && "24px"};
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 2rem;
`;

export const CommonRowContainer = styled.div`
  margin-bottom: 10px;
`;

export const CommonSwitch = styled(Switch)`
  &&& .ant-switch-checked {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const CommonBreakWordContainer = styled.div<CommonBreakContainerProps>`
  width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: ${(props) => (props.font ? props.theme.fontSizes[props.font] : props.theme.fontSizes.medium)};
  margin: ${(props) => props.margin};
`;

export const CommonBreakWordParagraph = styled.div<CommonBreakContainerProps>`
  width: ${(props) => (props.width ? props.width : "250px")};
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const CommonCol = styled(Col)<CommonColProps>`
  padding: ${(props) => props.padding} !important;
`;

import moment from "moment";
import APPConfig from "../config";
import "moment-timezone";

/**
 *
 */
export default class DateUtils {
  /**
   *
   * @param dateString
   * @param format
   */
  static format(dateString: any, format: string = "MM-DD-YYYY") {
    return dateString ? moment(dateString).tz("America/New_York").format(format) : "";
  }

  /**
   *
   * @param dateString
   * @param format
   */
  static formatTZ(dateString: any, isTime = false, format: string = "MM-DD-YYYY", timeZone: string = "") {
    timeZone = timeZone ? timeZone : APPConfig.DEFAULT_TIMEZONE;
    return dateString ? moment(dateString).tz(timeZone, isTime).format(format) : "";
  }

  /**
   *
   * @param dateString
   * @param format
   */
  static convertTz(dateString: any) {
    let timeZone = APPConfig.DEFAULT_TIMEZONE;
    return moment(dateString).tz(timeZone);
  }
  static quarterFormat = (value:any) => `Q${Math.ceil(value.quarter())}-${value.year()}`;
  static currentYear: string = moment().tz(APPConfig.DEFAULT_TIMEZONE).year().toString();
  static currentMonth: string = moment().tz(APPConfig.DEFAULT_TIMEZONE).add(1, 'month').month().toString();
  static currentQuarter: string = moment().tz(APPConfig.DEFAULT_TIMEZONE).quarter().toString();
  static currentDay: string = moment().tz(APPConfig.DEFAULT_TIMEZONE).format("MM-DD-YYYY");

  /**
   * @param monthNumber
   */
  static monthNumberToName(monthNumber: number): string {
    return moment()
      .month(monthNumber - 1)
      .format("MMMM");
  };
}

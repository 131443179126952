import React from "react";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  ExclamationCircleFilled,
  ClockCircleFilled 
} from "@ant-design/icons";
import { ModalIconContainer, ModalContentStyle, ModalTitleStyle } from "./ComponentsStyle";
//style
const ERROR_COLOR = "#ff4d4f";
const SUCCESS_COLOR = "#508553";

const iconStyle = { fontSize: 80, borderRadius: 80 };

interface ModalContentProps {
  title?: string;
  content: HTMLElement;
  type: "success" | "error" | "confirm" | "loading";
}

const ModalError = () => {
  return (
    <div>
      <ModalIconContainer>
        <div>
          <CloseCircleFilled style={{ ...iconStyle, color: ERROR_COLOR }} />
        </div>
      </ModalIconContainer>
    </div>
  );
};

const ModalSuccess = () => {
  return (
    <div>
      <ModalIconContainer>
        <div>
          <CheckCircleFilled style={{ ...iconStyle, color: SUCCESS_COLOR }} />
        </div>
      </ModalIconContainer>
    </div>
  );
};

const ModalConfirm = () => {
  return (
    <div>
      <ModalIconContainer>
        <div>
          <ExclamationCircleFilled style={{ ...iconStyle, color: SUCCESS_COLOR }} />
        </div>
      </ModalIconContainer>
    </div>
  );
};

const ModalLoading = () => {
  return (
    <div>
      <ModalIconContainer>
        <div>
          <ClockCircleFilled style={{ ...iconStyle, color: SUCCESS_COLOR }} />
        </div>
      </ModalIconContainer>
    </div>
  );
};

const ModalContent = (props: ModalContentProps) => {
  let RenderModalType;
  switch (props.type) {
    case "error":
      RenderModalType = ModalError;
      break;
    case "success":
      RenderModalType = ModalSuccess;
      break;
    case "confirm":
      RenderModalType = ModalConfirm;
      break;
    case "loading":
      RenderModalType = ModalLoading;
      break;
  }
  return (
    <div>
      {RenderModalType && <RenderModalType />}
      <div>
        <ModalTitleStyle>{props.title && props.title}</ModalTitleStyle>
        <ModalContentStyle>{props.content}</ModalContentStyle>
      </div>
    </div>
  );
};

export default ModalContent;

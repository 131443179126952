import { Divider, Menu } from "antd";
import styled from "styled-components";
interface StyledLogoProps {
  iscollapsed: boolean;
}

interface StyledMenuProps {
  iscollapsed: boolean;
}
interface StyledRowContainerProps {
  iscollapsed: boolean;
}
interface StyledSideBarProps {
  iscollapsed?: boolean;
  isLogoutCollapsed?: boolean;
}

interface StyledTextProps {
  width?: string;
  color?: string;
  fontWeight?: string;
  isShrink?: boolean;
  margin?: string;
  marginLeft?: string;
  paddingLeft?: string;
  lineHeight?: number;
}

export const StyledMenu = styled(Menu)<StyledMenuProps>`
  padding: 0 16px !important;
  height: 100vh;
  border: none;
  background-color: ${(props) => props.theme.colors.primary};
  width: ${(props) => (props.iscollapsed ? "95px" : "250px")} !important;
`;

export const StyledMenuItem = styled(Menu.Item)<StyledSideBarProps>`
  margin-right: -16px;
  height: 37px !important;
  margin-bottom: ${(props) => (props.isLogoutCollapsed ? "-10px" : "0")}!important;
  color: ${(props) => props.theme.colors.white};
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: ${(props) => (props.iscollapsed ? "30px" : "20px")};
    height: ${(props) => (props.iscollapsed ? "30px" : "20px")};
    fill: ${(props) => props.theme.colors.white};
    margin-left: ${(props) => props.isLogoutCollapsed && "-1.1em"}!important;
    margin-right ${(props) => !props.isLogoutCollapsed && "-1.1em"}!important;
  }

  span {
    margin-left: ${(props) => props.isLogoutCollapsed && "-0.04em"}!important;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.primary} !important;

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  &.ant-menu-item-selected {
    color: ${(props) => props.theme.colors.primary} !important;
    background-color: ${(props) => props.theme.colors.white} !important;

    span {
      color: ${(props) => props.theme.colors.primary} !important;
    }

    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  span {
    padding-left: 15px !important;
    display: ${(props) => (props.iscollapsed ? "none" : "inline")} !important;
    font-size: ${(props) => props.iscollapsed && "14px"};
  }
`;

export const StyledRowContainer = styled.div<StyledRowContainerProps>`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.tertiary};
  width: ${(props) => props.iscollapsed && "95px"};
`;

export const StyledLogo = styled.img<StyledLogoProps>`
  width: ${(props) => (props.iscollapsed ? "0px" : "150px")};
  margin: ${(props) => (props.iscollapsed ? "0px" : "20px")};
`;

export const StyledLogoCollapsed = styled.img<StyledLogoProps>`
  width: ${(props) => (!props.iscollapsed ? "0px" : "4.8em")};
  margin: ${(props) => (!props.iscollapsed ? "0px" : "20px")};
`;

export const StyledProfileContainer = styled.div<StyledSideBarProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  width: ${(props) => (props.isLogoutCollapsed ? "5.5em" : "218px")};
  margin-bottom: ${(props) => props.isLogoutCollapsed && "20px"};

  .ant-menu-item-active {
    color: ${(props) => props.theme.colors.white};
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.colors.white} !important;
  @media (max-height: 680px) {
    margin: 10px 0px 10px 0px;
  }
`;

export const StyledProfileAvatarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .ant-avatar {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const HoverClickableTextMedium = styled.p<StyledTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.2")};
  display: flex;
  align-items: end;
  padding: 6px;
  cursor: pointer;
  @media (max-height: 680px) {
    font-size: ${(props) => props.isShrink && "16px"};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.primary} !important;
    border-radius: 5px;
  }
`;

export const ActiveTextMedium = styled.p<StyledTextProps>`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.2")};
  display: flex;
  align-items: end;
  padding: 6px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white} !important;
  color: ${(props) => props.theme.colors.primary} !important;
  border-radius: 5px;
  @media (max-height: 680px) {
    font-size: ${(props) => props.isShrink && "16px"};
  }
`;

export const TextMedium = styled.p<StyledTextProps>`
  color: ${(props) => (props.color ? props.theme.colors[props.color!] : props.theme.colors.black)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "1.2")};
  display: flex;
  align-items: end;
  padding: 0 0 0 6px;
  @media (max-height: 680px) {
    font-size: ${(props) => props.isShrink && "16px"};
  }
`;

import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  StyledCollapseSideMenuLeft,
  StyledCollapseSideMenuRight
} from "../../../../components/icons/Icons";
import { StyledContainer, StyledHeaderItemContainer } from "./HeaderViewStyle";

const HeaderView = (props: any) => {
  const { history } = props;

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      // onLogout();
      localStorage.clear();
      history.push("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSidebar = () => {
    props.collapseToggle(!props.siderCollapsed);
  };

  return (
    <>
      <StyledContainer>
        <StyledHeaderItemContainer onClick={toggleSidebar}>
          {props.siderCollapsed ? <StyledCollapseSideMenuRight isMirrored size="1.5em" /> : <StyledCollapseSideMenuLeft size="1.5em" />}
        </StyledHeaderItemContainer>
      </StyledContainer>
    </>
  );
};

export default withRouter(HeaderView);

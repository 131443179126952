import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import {
  CommonAlignCenterContainer,
} from "../../../../components/common/AtomCommonComponents";
import { UserOutlined } from "@ant-design/icons";
import {
  StyledAdminManagementIcon,
  StyledCustomerIcon,
  StyledHistoryIcon,
  StyledHomeDeliveryIcon,
  StyledProductIcon,
  StyledProgramIcon,
  StyledReportIcon,
  StyledStoreIcon,
  StyledTellerIcon,
  StyledVoucherIcon,
} from "../../../../components/icons/Icons";
import {
  ActiveTextMedium,
  HoverClickableTextMedium,
  StyledDivider,
  StyledLogo,
  StyledLogoCollapsed,
  StyledMenu,
  StyledMenuItem,
  StyledProfileAvatarContainer,
  StyledProfileContainer,
  StyledRowContainer,
  TextMedium,
} from "./SideMenuViewStyles";
import logo from "./images/svg/LogoBig.svg";
import logoCollapsed from "./images/svg/LogoSmall.svg";
import APITokens from "../../../../services/api/APITokens";
import AuthService from "../../../../services/api/AuthService";
import { Avatar } from "antd";
import TextFormatUtils from "../../../../utils/TextFormatUtils";
import DialogUtils from "../../../../utils/DialogUtils";

const authService = new AuthService();

const getUser = () => {
  const data: any = localStorage.getItem("user");
  return data;
};

const SideMenuView = ({ siderCollapsed, collapseToggle }: any) => {
  const location = useLocation();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const data = JSON.parse(getUser());
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  		
  useEffect(() => {
    function onlineHandler() {
      	setIsOnline(true);
    }
	
    function offlineHandler() {
      	setIsOnline(false);
        isOffline()
    }
	
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

	
    return () => {
      	window.removeEventListener("online", onlineHandler);
      	window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split("/")[1]}`);
    setCurrentPath(keyPath);
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!data) {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyPathTransformer = (value: string) => {
    switch (value) {
      case "/admin-management": {
        return "/admin-management";
      }
      case "/market-details": {
        return "/market-details";
      }
      case "/market-managers": {
        return "/market-managers";
      }
      case "/customer-management": {
        return "/customer-management";
      }
      case "/product-management": {
        return "/product-management";
      }
      case "/discount-management": {
        return "/discount-management";
      }
      case "/transaction-history": {
        return "/transaction-history";
      }
      case "/report": {
        return "/report";
      }
      case "/program-management": {
        return "/program-management";
      }
      case "/home-delivery-management": {
        return "/home-delivery-management";
      }
      default: {
        return "";
      }
    }
  };

  const onLogout = async () => {
    const tokens = APITokens.getLocalTokens() || {};
    try {
      await authService.logout(tokens.access_token!, tokens.refresh_token || "temp");
      localStorage.clear();
      history.push("/login");
    } catch (err) {
      console.error("Error occurred during logout:", err);
    }
  };

  const onToggleMenus = (action: string) => {
    if(!isOnline){
      isOffline();
    }
    if (window.innerWidth <= 768) {
      collapseToggle(!siderCollapsed);
    }
    setCurrentPath(action);
    history.push(action);
  };

  const isOffline = () => {
    
    let content = "No network connection. Make sure that Wi-Fi or Cellular Mobile Data is turned on, then try again."
    DialogUtils.error(content);
  }

  const profileInfo = {
    name: data ? data.first_name + " " + data.last_name : null,
    type: data ? data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1) : null,
  };

  const sidebarPages = [
    {
      name: "Admins",
      url: "/admin-management",
      icon: <StyledAdminManagementIcon />,
    },
    {
      name: "Market Details",
      url: "/market-details",
      icon: <StyledStoreIcon />,
    },
    {
      name: "Market Managers",
      url: "/market-managers",
      icon: <StyledTellerIcon />,
    },
    {
      name: "Customers",
      url: "/customer-management",
      icon: <StyledCustomerIcon />,
    },
    {
      name: "Products",
      url: "/product-management",
      icon: <StyledProductIcon />,
    },
    {
      name: "Discounts",
      url: "/discount-management",
      icon: <StyledVoucherIcon />,
    },
    {
      name: "Programs",
      url: "/program-management",
      icon: <StyledProgramIcon/>
    },
    {
      name: "Home Delivery",
      url: "/home-delivery-management",
      icon: <StyledHomeDeliveryIcon/>
    },
    {
      name: "Transaction History",
      url: "/transaction-history",
      icon: <StyledHistoryIcon />,
    },
    {
      name: "Reports",
      url: "/report",
      icon: <StyledReportIcon />,
    },
  ];

  return (
    <StyledRowContainer iscollapsed={siderCollapsed}>
      <StyledMenu
        iscollapsed={siderCollapsed}
        selectedKeys={[currentPath]}
        defaultSelectedKeys={[currentPath]}
      >
        {/* Logo Contianer*/}
        <CommonAlignCenterContainer>
          <StyledLogo iscollapsed={siderCollapsed} src={logo} alt={"logo"} />
          <StyledLogoCollapsed iscollapsed={siderCollapsed} src={logoCollapsed} alt={"logo"} />
        </CommonAlignCenterContainer>
        {sidebarPages.map(({ name, url, icon }) => (
          <StyledMenuItem
            key={url}
            onClick={() => onToggleMenus(url)}
            iscollapsed={siderCollapsed}
            icon={icon}
          >
            <span>{name}</span>
          </StyledMenuItem>
        ))}
        {!siderCollapsed ? (
          <>
            <StyledProfileContainer>
              <StyledMenuItem
                iscollapsed={siderCollapsed}
                icon={<LogoutOutlined />}
                onClick={() => onLogout()}
                isLogoutCollapsed
              >
                <span>Logout</span>
              </StyledMenuItem>
              <StyledDivider />

              <StyledProfileAvatarContainer>
                <div>
                  <Avatar size={45} icon={<UserOutlined />} />
                </div>
                <div>
                  {window.location.pathname === "/admin-profile" ? (
                    <ActiveTextMedium isShrink color="white">
                      {TextFormatUtils.ellipsis(profileInfo.name ? profileInfo.name : " ", 30)}
                    </ActiveTextMedium>
                  ) : (
                    <HoverClickableTextMedium
                      onClick={() => {
                        history.push("/admin-profile");
                      }}
                      isShrink
                      color="white"
                    >
                      {TextFormatUtils.ellipsis(profileInfo.name ? profileInfo.name : " ", 30)}
                    </HoverClickableTextMedium>
                  )}
                  <TextMedium lineHeight={0.3} isShrink color="secondary">
                    {profileInfo.type}
                  </TextMedium>
                </div>
              </StyledProfileAvatarContainer>
            </StyledProfileContainer>
          </>
        ) : (
          <StyledProfileContainer isLogoutCollapsed>
            <StyledMenuItem key={1} onClick={() => onLogout()} icon={<LogoutOutlined />} />
          </StyledProfileContainer>
        )}
      </StyledMenu>
    </StyledRowContainer>
  );
};

export default SideMenuView;

import APIService from "./APIService";



class AuthService extends APIService
{
  verifyResetPasswordToken = async (token: string) => {
    const result = await this.post(
      { token },
      "auth/verify-reset-password-token"
    );
    return result ? result.data : null;
  };

   login = async ({email, password}:any) => {
      const result = await this.post({email, password}, 'auth/login');
        
      return result ? result.data : null;
   }

   forgotPassword = async ({email}:any) => {
      const result = await this.post({email}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async (token:string, new_password:string, confirm_password:string) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }

   logout = async (access_token: string, refresh_token: string) => {
      const result = await this.post({ refresh_token, access_token }, 'auth/logout');
      return result ? result.data : null;
   }

   send2fa = async (contact:any) => {
      const result = await this.post({contact}, 'auth/send2fa');
        
      return result ? result.data : null;
   }

   verify2fa = async ({code, contact_num, serviceSid}: any) => {
      const result = await this.post({code, contact_num, serviceSid}, 'auth/verify2fa');
        
      return result ? result.data : null;
   }

   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
   }

   verifyUser = async (id: any) => {
      const result = await this.get(`auth/verify-user/${id}`)
      return result ? result.data : null;
   }

   update2fa = async (id: any, is_enable: boolean) => {
      const result = await this.put({is_enable},`auth/2fa/${id}`);
      return result ? result.data : null;
   }

   updateContactNumber = async(id: any, newContactNumber:any) =>{
      const result = await this.put({newContactNumber}, `auth/update-contact-number/${id}`);
      return result ? result.data : null;
   }

}

export default AuthService
import axios from "axios";
import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { isArray } from "util";
import Loader from "./components/Loader";
import lazyComponentLoader from "./hoc/LazyLoader";
import ProtectedRoute from "./hoc/ProtectedRoute";
import PublicRoute from "./hoc/PublicRoute";
import AuthService from "./services/api/AuthService";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import { GlobalStyle } from "./theme/globalStyle";

interface IAppState {
  isLoading: Boolean;
  errorVisible: Boolean;
}
const authService = new AuthService();
const Login: any = lazyComponentLoader(() => import("./views/auth/login/LoginView"));
const Forgot: any = lazyComponentLoader(() => import("./views/auth/forgot/ForgotView"));
const Reset: any = lazyComponentLoader(() => import("./views/auth/reset/ResetView"));
const Profile: any = lazyComponentLoader(() => import("./views/admin-profile/AdminProfileView"));
const Admin: any = lazyComponentLoader(
  () => import("./views/admin-management/AdminManagementView")
);
const MarketListView: any = lazyComponentLoader(
  () => import("./views/market/list/MarketDetailsViewList")
);
const MarketManager: any = lazyComponentLoader(
  () => import("./views/teller/MarketManagerViewList")
);
const Customer: any = lazyComponentLoader(() => import("./views/customer/CustomerView"));
const Product: any = lazyComponentLoader(() => import("./views/product/ProductView"));
const Discount: any = lazyComponentLoader(() => import("./views/voucher/DiscountViewList"));
const Transaction: any = lazyComponentLoader(() => import("./views/transaction/TransactionViewList"));
const Report: any = lazyComponentLoader(() => import("./views/report/ReportView"));
const Program: any = lazyComponentLoader(()=> import("./views/program/ProgramViewList"));
const HomeDelivery: any = lazyComponentLoader(()=>import("./views/home-delivery/list/HomeDeliveryViewList"));
const HomeDeliveryNewOrder: any = lazyComponentLoader(()=>import("./views/home-delivery/add/AddHomeDeliveryView"));
const HomeDeliveryEditOrder: any = lazyComponentLoader(()=>import("./views/home-delivery/edit/EditHomeDeliveryView"));
class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);
    //initialize state here
    this.state = {
      isLoading: false,
      errorVisible: true,
    };

    const self = this;
    axios.interceptors.request.use(
      function (config) {
        const url = config.url;
        const endPoint = (url || "/").split("/")[(url || "").split("/").length - 2];
        if (endPoint === "phone_validation" || endPoint === "name_validation") {
          self.setState({ isLoading: false });
        } else {
          self.setState({ isLoading: navigator.onLine });
        }
        if(!navigator.onLine && (endPoint === "auth")){
          let content = "No network connection. Make sure that Wi-Fi or Cellular Mobile Data is turned on, then try again."
           DialogUtils.error(content);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.setState({ isLoading: false });

        return response;
      },
      async (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            return <Redirect from="*" to="/login" />;
          } else {
            if (error.response) {
              const refreshToken = `"refresh_token" must be a string`;
              const accessToken = `"access_token" must be a string`;
              if (
                error.response?.data?.errors?.length > 0 &&
                error.response?.data?.errors.some(
                  (e: any) => e.message === refreshToken || e.message === accessToken
                )
              ) {
                localStorage.clear();
                <Redirect from="*" to="/login" />;
              } else {
                this.showError(error.response.data.errors);
              }
            } else {
              this.showError(error);
            }
            self.setState({ isLoading: false });
            return Promise.reject(error);
          }
        }
      }
    );
  }

  showError(errors: any) {
    let content: any = null;
    if (isArray(errors)) {
      // message.error(
      content = (
        <div>
          {errors.map((item: any, index: number) => {
            return <div key={Math.random().toString(6)}>{item.message}</div>;
          })}
        </div>
      );
      // );
    } else if (!navigator.onLine){
      // content = "No network connection. Make sure that Wi-Fi or Cellular Mobile Data is turned on, then try again."
    }else{
      content = errors?.message;
    }
    if(content){
      DialogUtils.error(content);
    }
  }



  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Switch>
              <PublicRoute
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact
                path="/login"
                component={Login}
              />
              <PublicRoute
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact
                path="/reset-password"
                component={Reset}
              />
              <PublicRoute
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact
                path="/change-password/:token"
                component={Reset}
              />
              <PublicRoute
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact
                path="/set-password/:token"
                component={Reset}
              />
              <PublicRoute
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
                exact
                path="/forgot-password"
                component={Forgot}
              />
              <LayoutView>
                <ProtectedRoute exact path="/admin-profile" component={Profile} />
                <ProtectedRoute exact path="/admin-management" component={Admin} />
                {/* Market Details */}
                <ProtectedRoute exact path="/market-details" component={MarketListView} />

                <ProtectedRoute exact path="/market-managers" component={MarketManager} />
                <ProtectedRoute exact path="/customer-management" component={Customer} />
                <ProtectedRoute exact path="/product-management" component={Product} />
                <ProtectedRoute exact path="/discount-management" component={Discount} />
                <ProtectedRoute exact path="/transaction-history" component={Transaction} />
                <ProtectedRoute exact path="/report" component={Report} />
                <ProtectedRoute exact path="/program-management" component={Program} />
                <ProtectedRoute exact path="/home-delivery-management" component={HomeDelivery} />
                <ProtectedRoute exact path="/home-delivery-management/new-order" component={HomeDeliveryNewOrder} />
                <ProtectedRoute exact path="/home-delivery-management/edit-order/:id" component={HomeDeliveryEditOrder} />
                <Route
                  render={(props: any) => {
                    const { key } = props.location;
                    const isLoggedIn = authService.isLoggedIn();
                    const access_token = localStorage.getItem("access_token");

                    if (isLoggedIn && !key) {
                      return <Redirect from="*" to="/admin-profile" />;
                    } else if (!isLoggedIn) {
                      return <Redirect from="*" to="/login" />;
                    }

                    if (!access_token) {
                      localStorage.clear();
                      return <Redirect from="*" to="/login" />;
                    }
                  }}
                />
              </LayoutView>
            </Switch>
          </Router>
          {this.state.isLoading ? <Loader /> : null}
        </ThemeProvider>
      </div>
    );
  }
}

export default App;

import React, { Component } from "react";
import { Spin } from "antd";
import { LoaderContainer } from "./ComponentsStyle";

class Loader extends Component {
  render() {
    return (
      <LoaderContainer>
        <Spin />
      </LoaderContainer>
    );
  }
}

export default Loader;

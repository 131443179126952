import React, { useState } from "react";
import { Layout } from "antd";
import SideMenuView from "./component/sidebar/SideMenuView";
import HeaderView from "./component/header/HeaderView";
import { Content } from "antd/lib/layout/layout";
import { StyledLayout } from "./LayoutViewStyle";

const { Sider } = Layout;

const LayoutView = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
  const [pageHeader, setPageHeader] = useState("");

  const toggleCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };
  const child = React.Children.map(children, (child) => {
    return React.cloneElement(child, { setPageHeader: setPageHeader });
  });
  return (
    <Layout>
      <Sider width={250} trigger={null} collapsed={collapsed} onCollapse={toggleCollapse}>
        <SideMenuView siderCollapsed={collapsed} collapseToggle={toggleCollapse} />
      </Sider>
      <StyledLayout>
        <HeaderView
          siderCollapsed={collapsed}
          collapseToggle={toggleCollapse}
          pageHeader={pageHeader}
        />
        <Content>{child}</Content>
      </StyledLayout>
    </Layout>
  );
};

export default LayoutView;
